<template>
  <div>
    <el-row>
      <el-col :span="8" v-if="!leftImg">
        <div :style="{height: contentStyleObj}" class="leftPadding">
          <!-- 风险评测 -->
          <div style="height:70%;padding: 26px;position: relative;"  v-loading="fxcpLoading">
            <!-- <h6>*温馨提示：风险评测不影响做账，可不操作！</h6> -->
            <img class="shield" src="@/assets/shield.png" v-if="jzLoading">
            <img class="circle" src="@/assets/circle.png" v-if="jzLoading">
            <span class="fengxian">风险评测</span>
            <img  class="shield3" src="@/assets/allShield.png" alt="" v-if="!jzLoading">
            <div class="top_button2">
              <qzf-button button_code="jz_jzsy" type="primary" v-if="this.jzsyWord" size="small" @success="gotoCarryForward()" plain>
                <el-icon><Coin /></el-icon> <span  > 结转损益</span>
              </qzf-button>
              <qzf-button button_code="jz_cxjzsy" type="primary" v-if="!this.jzsyWord" size="small" @success="gotoCarryForward()" plain>
                <el-icon><Coin /></el-icon> <span  > 重新结转损益</span>
              </qzf-button>
              <qzf-button button_code="jz_fjz" jz="false" v-if="settleStatus != ''" type="danger" size="small" @success="settlefan()" plain>
                <el-icon><Money /></el-icon><span  > 反结账</span>
              </qzf-button>
              <qzf-button button_code="jz_jz" jz="false" v-else type="danger" size="small" @success="goCarryOver()" plain>
                <el-icon><Money /></el-icon><span  > 结账</span>
              </qzf-button>
            </div>
          </div>
          
        </div>
      </el-col>
     
      <!-- 结账的list -->
      <el-col :span="16" v-if="!leftImg && this.jzsyWord">
        <div :style="{height: contentStyleObj}" style="overflow: auto;">
          <div class="descripBody"> 
            <span class="titleSpan"><i class="iconfont icon-pingzheng1"></i>  清单凭证</span>  
            <el-descriptions v-if="this.lossList" :column="1" size="large" border v-loading="this.jzLoading" class="bodyTitle">
              <el-descriptions-item label-class-name="my-label" class-name="my-content">
                <template #label>
                  <div class="cell-item" style="color:#3c6ff2">
                    检查项目
                  </div>
                </template>
                <div class="cell-item item_box" style="color:#3c6ff2">
                  <div class="item">
                    检查结果
                  </div>
                </div>
              </el-descriptions-item>
              <div v-for="item in this.qdpz" :key="item" >
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item item_box">
                      <span>{{item}}</span>
                    </div>
                  </template>
                </el-descriptions-item>
              </div>
            </el-descriptions>
          </div>
          <div class="descripBody">
            <span class="titleSpan"><i class="iconfont icon-shuiwubaobiao1"></i> 税金计提</span>
            <el-descriptions v-if="this.lossList" :column="1" size="large" border v-loading="this.jzLoading" class="bodyTitle">
              <el-descriptions-item label-class-name="my-label" class-name="my-content">
                <template #label>
                  <div class="cell-item" style="color:#3c6ff2">
                    检查项目
                  </div>
                </template>
                <div class="cell-item item_box" style="color:#3c6ff2">
                  <div class="item">
                    检查结果
                  </div>
                </div>
              </el-descriptions-item>
              <div v-for="item in this.sjjt" :key="item" >
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item item_box">
                      <span>{{item}}</span>
                    </div>
                  </template>
                </el-descriptions-item>
              </div>
            </el-descriptions>
          </div>
          <div class="descripBody">
            <span class="titleSpan"><i class="iconfont icon-yewushuju1"></i> 其他</span>
            <el-descriptions v-if="this.lossList" :column="1" size="large" border v-loading="this.jzLoading" class="bodyTitle">
              <el-descriptions-item label-class-name="my-label" class-name="my-content">
                <template #label>
                  <div class="cell-item" style="color:#3c6ff2">
                    检查项目
                  </div>
                </template>
                <div class="cell-item item_box" style="color:#3c6ff2">
                  <div class="item">
                    检查结果
                  </div>
                </div>
              </el-descriptions-item>
              <div v-for="item in this.qtxm" :key="item" >
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item item_box">
                      <span>{{item}}</span>
                    </div>
                  </template>
                </el-descriptions-item>
              </div>
            </el-descriptions>
          </div>
        </div>
      </el-col>

      <!-- 结转损益的list  todo-->
      <el-col :span="16" v-if="!leftImg && !this.jzsyWord">
        <div :style="{height: contentStyleObj}" style="overflow: auto;">
          <div class="descripBody">
            <span class="titleSpan"><i class="iconfont icon-pingzheng1"></i>  清单凭证</span>  
            <el-descriptions v-if="this.lossList" :column="1" size="large" border v-loading="this.jzLoading" class="bodyTitle">
              <el-descriptions-item label-class-name="my-label">
                <template #label>
                  <div class="cell-item" style="color:#3c6ff2">
                    检查项目
                  </div>
                </template>
                <div class="cell-item item_box" style="color:#3c6ff2">
                  <div class="item">
                    检查结果
                  </div>
                </div>
              </el-descriptions-item>
              <div v-for="item in this.qdpz" :key="item" >
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item item_box">
                      <span>{{item}}</span>
                    </div>
                  </template>
                </el-descriptions-item>
              </div>
            </el-descriptions>
          </div>
          <div class="descripBody">
            <span class="titleSpan"><i class="iconfont icon-shuiwubaobiao1"></i> 税金计提</span>
            <el-descriptions v-if="this.lossList" :column="1" size="large" border v-loading="this.jzLoading" class="bodyTitle">
              <el-descriptions-item label-class-name="my-label">
                <template #label>
                  <div class="cell-item" style="color:#3c6ff2">
                    检查项目
                  </div>
                </template>
                <div class="cell-item item_box" style="color:#3c6ff2">
                  <div class="item">
                    检查结果
                  </div>
                </div>
              </el-descriptions-item>
              <div v-for="item in this.sjjt" :key="item" >
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item item_box">
                      <span>{{item}}</span>
                    </div>
                  </template>
                </el-descriptions-item>
              </div>
            </el-descriptions>
          </div>
          <div class="descripBody">
            <span class="titleSpan"><i class="iconfont icon-yewushuju1"></i> 其他</span>
            <el-descriptions v-if="this.lossList" :column="1" size="large" border v-loading="this.jzLoading" class="bodyTitle">
              <el-descriptions-item label-class-name="my-label">
                <template #label>
                  <div class="cell-item" style="color:#3c6ff2">
                    检查项目
                  </div>
                </template>
                <div class="cell-item item_box" style="color:#3c6ff2">
                  <div class="item">
                    检查结果
                  </div>
                </div>
              </el-descriptions-item>
              <div v-for="item in this.qtxm" :key="item" >
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item item_box">
                      <span>{{item}}</span>
                    </div>
                  </template>
                </el-descriptions-item>
              </div>
            </el-descriptions>
          </div>
        </div>
      </el-col>

      

      <el-col :span="8" v-if="leftImg">
        <div :style="{height: contentStyleObj}" class="leftPadding2">
          <!-- 风险评测 -->
          <div style="height:40%;padding: 26px;" >
            <!-- <h6>*温馨提示：风险评测不影响做账，可不操作！</h6> -->
            <img class="shield" src="@/assets/shield.png">
            <img class="circle" src="@/assets/circle.png" v-if="jzLoading">
            <img class="circle2" src="@/assets/circle.png" v-if="!jzLoading">

            <span class="fengxian3" v-if="this.titleWOrd == '结账'">{{titleWOrd}}</span>
            <span class="fengxian4" v-else-if="this.titleWOrd == '反结账'">{{titleWOrd}}</span>

            <span class="fengxian2" v-else-if="this.titleWOrd == '结转损益'">{{titleWOrd}}</span>
            <span class="fengxian5" v-else>{{titleWOrd}}</span>



            <div class="top_button3">
              <qzf-button button_code="jz_jzsy" type="primary" v-if="this.jzsyWord" size="small" @success="gotoCarryForward()" plain>
                <el-icon><Coin /></el-icon> <span  > 结转损益</span>
              </qzf-button>
              <qzf-button button_code="jz_cxjzsy" type="primary" v-if="!this.jzsyWord" size="small" @success="gotoCarryForward()" plain>
                <el-icon><Coin /></el-icon> <span  > 重新结转损益</span>
              </qzf-button>
              <qzf-button button_code="jz_fjz" jz="false" v-if="settleStatus != ''" type="danger" size="small" @success="settlefan()" plain>
                <el-icon><Money /></el-icon><span  > 反结账</span>
              </qzf-button>
              <qzf-button button_code="jz_jz" jz="false" v-else type="danger" size="small" @success="goCarryOver()" plain>
                <el-icon><Money /></el-icon><span  > 结账</span>
              </qzf-button>
            </div>
          </div>
        </div>
      </el-col>


      <!-- 结账list -->
      <el-col :span="16" v-if="leftImg">
        <div :style="{height: contentStyleObj}" style="overflow: auto;">
          <div class="descripBody"> 
            <span class="titleSpan"><i class="iconfont icon-pingzheng1"></i>  清单凭证</span>  
            <el-descriptions v-if="this.lossList" :column="1" size="large" border v-loading="this.jzLoading" class="bodyTitle">
              <el-descriptions-item label-class-name="my-label">
                <template #label>
                  <div class="cell-item" style="color:#3c6ff2">
                    检查项目
                  </div>
                </template>
                <div class="cell-item item_box" style="color:#3c6ff2">
                  <div class="item">
                    检查结果
                  </div>
                </div>
              </el-descriptions-item>
              <div v-for="item in this.qdpz" :key="item" >
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item item_box">
                      <span>{{item.substring(0,item.indexOf(":"))}}</span>
                    </div>
                  </template>
                  <div class="cell-item item_box">
                    <div class="item" v-if="item.match('错误')">
                      <i class="iconfont icon-cuowu"></i>
                      <span>{{item.substring(item.indexOf(":") + 1,item.length)}}</span>
                    </div>
                    <div class="item" v-else-if="item.match('提示')">
                      <i class="iconfont icon-gantanhao"></i>
                      <span>{{item.substring(item.indexOf(":") + 1,item.length)}}</span>

                    </div>
                    <div class="item" v-else-if="item.match('正常')">
                      <i class="iconfont icon-duihao"></i>
                      <span>{{item.substring(item.indexOf(":") + 1,item.length)}}</span>
                    </div>
                  </div>
                </el-descriptions-item>
              </div>
            </el-descriptions>
          </div>
          <div class="descripBody">
            <span class="titleSpan"><i class="iconfont icon-shuiwubaobiao1"></i> 税金计提</span>
            <el-descriptions v-if="this.lossList" :column="1" size="large" border v-loading="this.jzLoading" class="bodyTitle">
              <el-descriptions-item label-class-name="my-label">
                <template #label>
                  <div class="cell-item" style="color:#3c6ff2">
                    检查项目
                  </div>
                </template>
                <div class="cell-item item_box" style="color:#3c6ff2">
                  <div class="item">
                    检查结果
                  </div>
                </div>
              </el-descriptions-item>
              <div v-for="item in this.sjjt" :key="item" >
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item item_box">
                      <span>{{item.substring(0,item.indexOf(":"))}}</span>
                    </div>
                  </template>
                  <div class="cell-item item_box">
                    <div class="item" v-if="item.match('错误')">
                      <i class="iconfont icon-cuowu"></i>
                      <span>{{item.substring(item.indexOf(":") + 1,item.length)}}</span>
                    </div>
                    <div class="item" v-else-if="item.match('提示')">
                      <i class="iconfont icon-gantanhao"></i>
                      <span>{{item.substring(item.indexOf(":") + 1,item.length)}}</span>

                    </div>
                    <div class="item" v-else-if="item.match('正常')">
                      <i class="iconfont icon-duihao"></i>
                      <span>{{item.substring(item.indexOf(":") + 1,item.length)}}</span>
                    </div>
                  </div>
                </el-descriptions-item>
              </div>
            </el-descriptions>
          </div>
          <div class="descripBody">
            <span class="titleSpan"><i class="iconfont icon-yewushuju1"></i> 其他</span>
            <el-descriptions v-if="this.lossList" :column="1" size="large" border v-loading="this.jzLoading" class="bodyTitle">
              <el-descriptions-item label-class-name="my-label">
                <template #label>
                  <div class="cell-item" style="color:#3c6ff2">
                    检查项目
                  </div>
                </template>
                <div class="cell-item item_box" style="color:#3c6ff2">
                  <div class="item">
                    检查结果
                  </div>
                </div>
              </el-descriptions-item>
              <div v-for="item in this.qtxm" :key="item" >
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item item_box">
                      <span>{{item.substring(0,item.indexOf(":"))}}</span>
                    </div>
                  </template>
                  <div class="cell-item item_box">
                    <div class="item" v-if="item.match('错误')">
                      <i class="iconfont icon-cuowu"></i>
                      <span>{{item.substring(item.indexOf(":") + 1,item.length)}}</span>
                    </div>
                    <div class="item" v-else-if="item.match('提示')">
                      <i class="iconfont icon-gantanhao"></i>
                      <span>{{item.substring(item.indexOf(":") + 1,item.length)}}</span>

                    </div>
                    <div class="item" v-else-if="item.match('正常')">
                      <i class="iconfont icon-duihao"></i>
                      <span>{{item.substring(item.indexOf(":") + 1,item.length)}}</span>
                    </div>
                  </div>
                </el-descriptions-item>
              </div>
            </el-descriptions>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { profitAndLoss, reverseCheckout, checkOut ,carrOverList } from '@/api/carryover.js'
import { taxCategoryList } from '../../api/invoice';

export default {
  name: 'newSettleAccount',

  data() {
    return {
      contentStyleObj:{},
      settleStatus:'',
      comId:0,
      period:'',
      lossList:[],
      list:[],
      leftImg:false,
      tableData6:[
        {
          id: 1,
          name: '结转损益',
          status: false
        }
      ],
      fxcpLoading:false,
      titleWOrd:'结转损益',
      jzLoading:false,
      jzsyWord:true
    };
  },
  created(){
    this.contentStyleObj= this.$getHeight(123)
    this.init()
    this.getList()
  },

  mounted() {
    
  },

  methods: {
    init(){
      this.settleStatus = this.$checkSettleStatus()
      if(this.$store.getters['user/comInfo'].jzsy == 0 ){
        this.jzsyWord = true
        this.qdpz = ["清单凭证","工资","凭证","会计科目","资产负债表"]
        this.sjjt = ["会计报表","税务报表","工资表"]
        this.qtxm = ["科目余额表","福利费","业务招待费","成本收入","资金负数","存货负数","资产金额","广告超标","收入超标"]
      }else{
        this.jzsyWord = false
        this.qdpz = ["清单凭证","工资","凭证","科目"]
        this.sjjt = ["税务报表"]
        this.qtxm = ["科目余额表"]
        if(this.settleStatus != ''){
          this.qdpz = ["清单凭证","工资","凭证","会计科目","资产负债表"]
          this.sjjt = ["会计报表","税务报表","工资表"]
          this.qtxm = ["科目余额表","福利费","业务招待费","成本收入","资金负数","存货负数","资产金额","广告超标","收入超标"]
        }
      }
    },
    getList(){
      carrOverList({type:'jzsy'}).then(res => {
        if(res.data.data.list.length != 1){
          this.jzLoading = false;
          this.jzsyWord = false
          this.leftImg = true
          if(res.data.data.list){
            this.lossList = res.data.data.list
            if(this.lossList[6]){
              this.qdpz = [this.lossList[1],this.lossList[2],this.lossList[3],this.lossList[4],this.lossList[6]]
            }else{
              this.qdpz = [this.lossList[1],this.lossList[2],this.lossList[3],this.lossList[4]]
            }
            this.sjjt = [this.lossList[5]]
            if(this.lossList[8]){
              this.qtxm = [this.lossList[0],this.lossList[7],this.lossList[8]]
            }else if(this.lossList[7]){
              this.qtxm = [this.lossList[0],this.lossList[7]]
            }else{
              this.qtxm = [this.lossList[0]]
            }
          } else{
            this.lossList = []
          }
        }
      })
    },

     //反结账
     settlefan(){
      this.jzLoading = true;

      this.titleWOrd = '反结账'
      this.lossList = []
      let param = {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period
      }
      reverseCheckout([param]).then(res=>{
      this.jzLoading = false;
        if(res.data.msg != "success"){
          return
        }
        this.$store.dispatch('commons/InitPeriod', param.comId).then(res=>{
          this.leftImg = false
          this.$qzfMessage('反结账成功')
          this.init()
        })
      })
    },
    //结账
    goCarryOver(){
      this.leftImg = true
      this.jzLoading = true;
      this.titleWOrd = '结账'

      let param = {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period
      }
      checkOut([param]).then(res=>{
        this.jzLoading = false;
        if(res.data.msg == "success"){
          if(res.data.data.status){
            this.$message({
              type: 'success',
              message: '结账成功'
            });
          } else{
            this.$message({
              type: 'error',
              message: '结账失败'
            });
          }
          this.$store.dispatch('commons/InitPeriod', param.comId).then(res=>{
            this.init()
          })
          
        }
        if(res.data.data.list){
          this.lossList = res.data.data.list
          this.qdpz = [this.lossList[0],this.lossList[1],this.lossList[2],this.lossList[4],this.lossList[5]]
          this.sjjt = [this.lossList[6],this.lossList[7],this.lossList[8]]
          this.qtxm = [this.lossList[9],this.lossList[10],this.lossList[11],this.lossList[12],this.lossList[13],this.lossList[14],this.lossList[15],this.lossList[16]]
        } else{
          this.lossList = []
        }
      })
    },
    // 结转损益
    gotoCarryForward() {
      let comInfo = this.$store.getters["user/comInfo"]
      this.jzLoading = true;
      this.leftImg = true
      this.titleWOrd = '结转损益'


      profitAndLoss({}).then(res => {
        this.jzLoading = false;
        console.log(this.jzLoading);
        if(res.data.msg == "success"){
          if(res.data.data.status){
            this.$message({
              type: 'success',
              message: '结转损益成功'
            });
            comInfo.jzsy = 1
            this.$store.dispatch("user/setComInfo", comInfo)
            this.titleWOrd = '重新结转损益'
            this.jzsyWord = false
          } else{
            this.$message({
              type: 'error',
              message: '结转损益失败'
            });
          }
        }
        if(res.data.data.list){
          this.lossList = res.data.data.list
          this.qdpz = [this.lossList[1],this.lossList[2],this.lossList[3],this.lossList[4]]
          this.sjjt = [this.lossList[5]]
          this.qtxm = [this.lossList[0]]
        } else{
          this.lossList = []
        }
      })
    },

  },
};
</script>

<style lang="scss" scoped>

.item_box{
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .item{
    // display: flex;
    text-align: center;
    // flex-direction:row;
    // justify-content : flex-start;
    // border-bottom: 1px dashed #ccc;
    i{
      // line-height: 36px;
      font-size: 17px;
    }
    span{
      display: inline-block;
      font-size: 14px;
      color: #666;
      // line-height: 36px;
      margin-left: 6px;
    }
  }
}
.top_button{
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.leftPadding{
padding: 20px;
background: linear-gradient(150deg, rgba(220, 237, 255,0.4) 0%,#c8d9fc 100% );
}

.leftPadding2{
padding: 20px;
background: linear-gradient(150deg, rgba(220, 237, 255,0.4) 0%,#c8d9fc 100% );
position: relative;
}
.circle {
  animation-duration: 1s;
  animation-name: mymove;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  // animation-fill-mode: both;
  position: absolute;
  top: -230px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.circle2 {
  position: absolute;
  top: -230px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 230px;
  height:230px;
}
@keyframes mymove {
  from {
    width: 230px;
    height:230px;
    transform:rotate(0deg)
  }
  to{
    width: 230px;
    height:230px;
    transform: rotate(360deg);
  }
}

// @keyframes mymove2 {
//   0% {
//     width: 200px;
//     height:200px
//   }
//   100% {
//     width: 210px;
//     height: 210px;
//   }
// }
.shield{
  position: absolute;
  top: -230px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 140px;
  height:140px
}
.shield3{
  position: absolute;
  top: -222px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 240px;
  height:240px
}
.top_button2{
  position: absolute;
  top: 565px;
  bottom: 0;
  left: 25px;
  right: 0;
  margin: auto;
  width: 205px;
  height: 180px;
}



.top_button3{
  position: absolute;
  top: 512px;
  bottom: 0;
  left: 9px;
  right: 0;
  margin: auto;
  width: 205px;
  height: 180px;
}
.fengxian{
  position: absolute;
  top: -179px;
  left: 10px;
  right: 0;
  bottom: 0;
  width: 38px;
  height: 86px;
  margin: auto;
  z-index: 9;
  font-size: 13px;
  color: #3c6ff2;
}
.fengxian2{
  position: absolute;
  top: -191px;
  left: 2px;
  right: 0;
  bottom: 0;
  width: 38px;
  height: 86px;
  margin: auto;
  z-index: 9;
  font-size: 19px;
  color: #3c6ff2;
}
.fengxian5{
  position: absolute;
  top: -208px;
  left: 2px;
  right: 0;
  bottom: 0;
  width: 38px;
  height: 86px;
  margin: auto;
  z-index: 9;
  font-size: 18px;
  color: #3c6ff2;
}
.fengxian3{
  position: absolute;
  top: -165px;
  left: 2px;
  right: 0;
  bottom: 0;
  width: 38px;
  height: 86px;
  margin: auto;
  z-index: 9;
  font-size: 19px;
  color: #3c6ff2;
}
.fengxian4{
  position: absolute;
  top: -171px;
  left: 2px;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 86px;
  margin: auto;
  z-index: 9;
  font-size: 19px;
  color: #3c6ff2; 
}

.descripBody{
  padding: 12px;
}
.titleSpan{
  color: #3c6ff2;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
  display: inline-block;
  margin-top: 10px;
}

.bodyTitle{
  border-top: 1px solid #3c6ff2;
}

</style>
<style>
.my-label{
  width: 150px;
}
</style>